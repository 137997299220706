<template>
  <v-card elevation="1">
    <v-form ref="form" v-model="valid" lazy-validation class="pt-4">
      <v-row class="px-6 pt-2">
        <v-col cols="4">
          <v-text-field
            v-model="formData.titulo"
            placeholder="Cadastro de artigo"
            outlined
            dense
            :clearable="true"
            label="Título do artigo"
            required
            :rules="rules.genericRules"
          >
          </v-text-field>

          <v-autocomplete
            v-model="formData.tags"
            :items="listaDeTags"
            hide-no-data
            hide-selected
            item-text="nome"
            item-value="id"
            label="Tags"
            placeholder="Escolha as tags"
            outlined
            dense
            multiple
            small-chips
            chips
            :clearable="true"
            required
            :rules="rules.multiSelectRules"
            deletable-chips
            return-object
          ></v-autocomplete>

          <v-text-field
            v-model="formData.autor"
            placeholder="Autor ou autores do artigo"
            outlined
            dense
            :clearable="true"
            label="Autor"
            required
            :rules="rules.genericRules"
          >
          </v-text-field>

          <v-text-field
            v-model="formData.dataPublicacao"
            placeholder="Ano de publicação"
            outlined
            dense
            :clearable="true"
            label="Ano de publicação"
            required
            :rules="rules.genericRules"
            v-mask="'####'"
          >
          </v-text-field>

          <v-autocomplete
            v-model="formData.produtos"
            :items="listProducts"
            hide-no-data
            hide-selected
            item-text="nome"
            item-value="id"
            label="Pesquisa de Produtos"
            placeholder="Escolha as produtos"
            outlined
            dense
            multiple
            small-chips
            chips
            :clearable="true"
            required
            :rules="rules.multiSelectRules"
            deletable-chips
            return-object
          ></v-autocomplete>

          <v-file-input
            v-model="formData.arquivo"
            label="Arquivo"
            placeholder="Selecione um arquivo"
            outlined
            dense
            multiple
            :clearable="true"
            required
            :rules="!isEdit ? rules.genericRules : []"
            small-chips
            accept=".pdf"
          ></v-file-input>
        </v-col>
      </v-row>
    </v-form>
    <v-card-actions class="px-6 pb-4">
      <v-btn
        depressed
        color="fmq_gray"
        dark
        @click="send"
        :loading="loadingBtn"
        >{{ isEdit ? "Editar" : "Criar" }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { rules } from "@/utils/rules.js";
export default {
  name: "ArtigoFormulario",
  props: {
    loadingBtn: {
      type: Boolean,
      default: false,
    },
    listaDeTags: {
      type: Array,
      required: true,
    },
    listProducts: {
      type: Array,
      required: true,
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    formData: {
      autor: null,
      titulo: null,
      dataPublicacao: null,
      tags: [],
      produtos: [],
      arquivo: null,
    },
    rules: rules,
    valid: true,
    isEdit: false,
    file: [],
  }),
  created() {
    if (this.$route.name === "ArtigoEditar") {
      this.formData = this.item;
      this.isEdit = true;
    }
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    async send() {
      if (this.formValid) {
        const data = await this.prepareFormData(this.formData);
        this.$emit("send", data);
      }
    },
    async prepareFormData(formData) {
      let data = new FormData();

      data.append("titulo", formData.titulo);
      data.append("autor", formData.autor);
      data.append("data_publicacao", formData.dataPublicacao);
      data.append("resumo", "");

      if (formData.arquivo) {
        data.append("arquivo", formData.arquivo[0]);
      }

      for (let i = 0; i < formData.tags.length; i++) {
        data.append("tags[]", formData.tags[i].id);
      }

      for (let i = 0; i < this.formData.produtos.length; i++) {
        data.append("produtos[]", this.formData.produtos[i].id);
      }

      return data;
    },
  },
  computed: {
    formValid() {
      return this.$refs.form.validate();
    },
  },
};
</script>

<style></style>
