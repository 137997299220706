<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      class="grey lighten-2 white--text"
    ></v-breadcrumbs>
    <div>
      <div class="pa-4">
        <div class="d-flex justify-space-between align-center mb-4">
          <v-btn small depressed color="fmq_gray" dark outlined to="/artigos">
            <v-icon small> mdi-arrow-left </v-icon>Voltar</v-btn
          >
        </div>
        <ArtigoFormulario
          @send="criar"
          :listaDeTags="listaDeTags"
          :listProducts="listProdutos"
          :loadingBtn="loadingBtn"
        />
      </div>
    </div>
    <AlertSuccess
      :dialog="success"
      dialogMessage="Artigo criado com sucesso"
      @close="goTo"
    />
    <AlertError :alertError="error" :messageError="messageError" />
  </div>
</template>

<script>
import { criarArtigo } from "@/services/artigos.js";
import { listarTags } from "@/services/tags.js";
import { listarProdutos } from "@/services/produtos.js";
import ArtigoFormulario from "@/components/artigos/ArtigoFormulario.vue";
export default {
  name: "ArtigoCriar",
  components: { ArtigoFormulario },
  data: () => ({
    breadcrumbs: [
      {
        text: "Artigos",
        disabled: false,
        to: "/artigos",
      },
      {
        text: "Criar Artigo Científico",
        disabled: true,
        to: "",
      },
    ],
    error: false,
    success: false,
    loadingBtn: false,
    messageError: null,
    listaDeTags: [],
    listProdutos: [],
  }),
  beforeCreate() {
    if(this.$store.state.user.data.tipo === "Representante") {
      this.$router.push({path: '/'})
    }
  },
  created() {
    this.listTags();
    this.listProducts();
  },
  methods: {
    async criar(event) {
      this.error = false;
      this.sucess = false;
      this.loadingBtn = true;
      try {
        await criarArtigo(event).then(() => {
          this.success = true;
        });
        this.loadingBtn = false;
      } catch (e) {
        this.loadingBtn = false;
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    goTo() {
      this.sucess = false;
      this.$router.push({ name: "Artigos" });
    },
    async listTags() {
      const resp = await listarTags();
      this.listaDeTags = resp.data;
    },
    async listProducts() {
      const resp = await listarProdutos();
      this.listProdutos = resp.data;
    },
  },
};
</script>

<style></style>
